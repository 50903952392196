<template>
  <div
    class="flex flex-col md:flex-row gap-4 md:gap-0 md:border border-int-grey-03"
  >
    <!-- locations -->

    <div class="flex-1 relative">
      <div class="h-14 md:h-full flex items-center border md:border-none border-int-grey-03">
        <input
          :id="id"
          ref="searchInput"
          v-model="searchTerm"
          aria-label="Predictive search input"
          type="text"
          :placeholder="placeholderText"
          class="h-full flex-1 px-0 pl-4 py-2 text-base placeholder-int-grey-02 border-none focus:outline-none focus:ring focus:ring-inset focus:ring-brand-blue-300"
          @keyup.esc.stop="searchTerm = ''"
          @keyup.enter.stop="emitSearch"
        />

        <label
          :for="id"
          class="absolute inset-y-0 left-0 m-2 pt-2 lg:flex flex-row items-center lg:cursor-pointer"
          @click="$refs.searchInput.focus()"
        >
          <Icon name="IconSearch" class="text-3xl"></Icon>
        </label>

        <button
          v-if="searchTerm"
          id="cancel"
          aria-label="Clear search"
          type="button"
          class="text-int-grey-01 absolute inset-y-0 right-0 top-0 mr-0.5 px-6 md:px-7 py-4 md:py-5 flex flex-row items-center focus:outline-none focus:ring focus:ring-inset focus:ring-brand-blue-300"
          @click="searchTerm = ''"
        >
          <Icon name="IconClose" class="-mb-1 text-lg md:text-base"></Icon>
          <span class="sr-only">Clear search</span>
        </button>
      </div>
      <div
        v-if="showSuggestions"
        id="results"
        aria-label="Predictive search results"
        class="z-10 absolute top-18 md:mt-0 pt-2 bg-white flex flex-col items-start w-auto gap-4 md:gap-1 pb-3 overflow-scroll border-b border-l border-r border-gray-300 text-brand-grey-600 max-h-[60vh]"
      >
        <button
          v-for="(result, index) in resultsApi"
          :key="result.name"
          :aria-label="`result ${index + 1}`"
          class="w-full px-6 text-left"
          @click="selectResult(result)"
        >
          {{ result.name }}
        </button>
      </div>
    </div>
    <slot></slot>
    <div>
      <A11yButton
        v-if="showButton"
        id="searchButton"
        ref="searchButton"
        component="button"
        variant="primary"
        type="button"
        aria-label="Submit search"
        class="space-x-0 w-full md:w-auto focus:outline-none focus:ring focus:ring-inset focus:ring-brand-blue-100"
        @click="emitSearch"
      >
        <div
          v-if="buttonText == ''"
          class="text-white w-full flex justify-center items-center md:py-2.5"
        >
          <Icon
            name="IconSearch"
            class="px-1 text-3xl hidden md:inline-block"
          ></Icon>
          <span class="inline-block md:hidden">Search</span>
          <Icon
            name="IconChevronRight"
            class="px-1 text-3xl inline-block md:hidden"
          ></Icon>
        </div>
        <div v-else class="text-white px-2 py-0.5 md:pt-3 md:pb-3.5 w-full">
          <span class="text-base">{{ buttonText }}</span>
        </div>
      </A11yButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const viewport = useViewport()

const emit = defineEmits(['update:searchParam', 'search'])

const resultSelected = ref()
const showResults = ref(false)
const timeout = ref<NodeJS.Timeout | null>(null)
const queuedKeyword = ref<string | null>(null)

const props = defineProps({
  resultsApi: {
    type: Array as PropType<{ name: string; id: string }[]>,
    default: () => []
  },
  id: {
    type: String,
    default: ''
  },
  showButton: {
    type: Boolean,
    default: false
  },
  buttonText: {
    type: String,
    default: ''
  },
  placeholderText: {
    type: String,
    default: ''
  },
  searchParam: {
    type: String,
    default: ''
  },
  debounce: {
    type: Number,
    default: 0
  }
})

const searchTerm = computed({
  get() {
    return props.searchParam
  },
  set(keyword: string) {
    showResults.value = keyword.length > 0

    if (props.debounce === 0) {
      emit('update:searchParam', keyword)
    } else {
      queuedKeyword.value = keyword
      if (timeout.value) {
        clearTimeout(timeout.value)
      }

      timeout.value = setTimeout(() => {
        emit('update:searchParam', keyword)
      }, props.debounce)
    }
  }
})

const showSuggestions = computed(
  () =>
    viewport.isGreaterOrEquals('lg') &&
    props.resultsApi?.length > 0 &&
    showResults.value
)
// TODO check on whether we need any more focus features
// const searchInput = ref<HTMLFormElement>()
// const setFocus = () => {
//   if (searchInput.value) searchInput.value.focus()
// }

const selectResult = (result: { name: string; id: string }) => {
  searchTerm.value = result.name
  resultSelected.value = result.id
  showResults.value = false
}

const emitSearch = () => {
  if (timeout.value) {
    clearTimeout(timeout.value)
  }

  showResults.value = false
  emit('search', queuedKeyword.value || searchTerm.value)
}
</script>
